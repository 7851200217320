import Vue from 'vue'
import Router from 'vue-router'
import {getToken} from "@/utils/token";

// // 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  routes: [
    {path: '*', redirect: '/index'},
    // 登录页面
    {path: '/login', name: 'login', component: () => import('@/views/login.vue')},
    {path: '/index', name: 'index', component: () => import('@/views/99.9/purchase.vue'), meta: {showTabbar: true}},

    {path: '/recharge', name: 'recharge', component: () => import('@/views/recharge.vue'), meta: {showTabbar: true}},
    {path: '/exchange', name: 'exchange', component: () => import('@/views/exchange.vue')},
    {path: '/coupon', name: 'coupon', component: () => import('@/views/coupon.vue')},
    {path: '/39.9/index', name: '39.9/index', component: () => import('@/views/39.9/index.vue')},
    {path: '/39.9/purchase', name: '39.9/purchase', component: () => import('@/views/39.9/purchase.vue')},
    // {path: '/49.9/purchase', name: '49.9/purchase', component: () => import('@/views/49.9/purchase.vue')},
    {path: '/forty-nine/activity', name: 'forty-nine/activity', component: () => import('@/views/49.9/purchase.vue') },
    {path: '/69.9/purchase', name: '69.9/purchase', component: () => import('@/views/69.9/purchase.vue')},
    // {path: '/99.9/purchase', name: '99.9/purchase', component: () => import('@/views/99.9/purchase.vue')},
    {path: '/q49.9/purchase', name: 'q49.9/purchase', component: () => import('@/views/q49.9/purchase.vue')},
    {path: '/q99.9/purchase', name: 'q99.9/purchase', component: () => import('@/views/q99.9/purchase.vue')},
    {path: '/pay-result', name: 'payResult', component: () => import('@/views/pay-result.vue')},
    {path: '/pay/history', name: 'payHistory', component: () => import('@/views/pay-history.vue')},
    {path: '/recharge/history', name: 'rechargeHistory', component: () => import('@/views/recharge-history.vue')},
    {path: '/zero', name: 'zero', component: () => import('@/views/zero/index.vue')},
    {path: '/privacy', name: 'privacy', component: () => import('@/views/zero/privacy.vue')},
    {path: '/pay', name: 'pay', component: () => import('@/views/pay/index.vue')},
    {path: "/activity-privacy", name: "activity-privacy",component: () => import("@/views/activity-privacy.vue"),},
    {path: '/my', name: 'my', component: () => import('@/views/my.vue'), meta: {showTabbar: true}}
  ],
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})

const whiteList = ['/login', '/index','/forty-nine/activity','/activity-privacy']

router.beforeEach(async (to, from, next) => {
  // console.log(to.path, from.path)

  const isLogin = getToken("customerId");

  // 未登录
  if (!isLogin) {
    if (!whiteList.includes(to.path)) {
      return next('/login')
    }

  } else {
    // 已登录
    if (to.path === '/login') {
      return next({path: '/index'})
    }
  }

  next()
})


export default router
