<template>
  <van-tabbar route fixed z-index="1100">
    <van-tabbar-item replace to="/index" icon="wap-home-o">首页</van-tabbar-item>

<!--    <van-tabbar-item replace to="/forty-nine/activity" icon="contact-o">活动</van-tabbar-item>-->
    <van-tabbar-item replace to="/recharge" icon="balance-pay">
      话费充值
    </van-tabbar-item>
    <van-tabbar-item replace to="/my" icon="contact-o">
      个人中心
    </van-tabbar-item>
  </van-tabbar>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
